<template>
    <markets-ui-container
        :class="cls"
        :skin="settings.skin"
        :showBorder="false"
        :showHeader="false"
        :showLoading="showLoading"
    >
        <div slot="content" :class="namespace('body')">
            <div :class="namespace('body-header')" ref="header">
                <markets-ui-select
                    hidden-label
                    label="label"
                    :skin="settings.skin"
                    :data-model="portfolioList"
                    @change="changePortfolio"
                ></markets-ui-select>
                <markets-ui-menus
                    :dataModel="layoutView"
                    @change="changeLayout"
                    :skin="settings.skin"
                    :collapse-mode="menusCollapse"
                ></markets-ui-menus>
            </div>
            <div :class="namespace('body-content')" ref="content">
                <template v-if="showWatchlist">
                    <mwc-markets-watchlist
                        ref="marketWatchList"
                        v-show="portfolioView === 'detail'"
                        :symbol="symbol"
                        :skin="settings.skin"
                        :mwc-id="mwcIds"
                        @row-double-click="doubleClick"
                        @settings-changed="changeSettings"
                        @hover-menu-click="hoverMenuClick"
                        :config="watchlistConfig"
                    >
                    </mwc-markets-watchlist>
                </template>
                <Overview
                    v-show="portfolioView === 'overview'"
                    :data-model="overviewData"
                    :skin="settings.skin"
                ></Overview>
            </div>
        </div>
    </markets-ui-container>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import 'mwc-markets-watchlist';
const { utils, mixins } = mwcMarketsCore;
import { DataPoints } from './datapoints';
import Overview from './overview.vue';
import axios from 'axios';
const axiosInstance = axios.create();
axiosInstance.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded';

axiosInstance.interceptors.response.use(
    res => {
        if (res.data && res.data.status && res.data.status.errorCode !== '0') {
            return Promise.reject(res.data);
        }
        return res.data;
    },
    error => {
        return Promise.reject(error);
    }
);
export default {
    name: 'mwc-markets-model-portfolio',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    components: { Overview },
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: true,
                    layout: 'mbg',
                    showGroup: true,
                    enableGroup: true,
                    groupKey: 'keyClassificationDefault',
                    columnType: 'basic',
                    frozenRow: 0,
                    skin: '',
                    symbol: '',
                    languageId: 'en-US'
                },
                intlNamespace: 'mwc-markets-model-detail'
            },
            overviewData: {},
            customRows: [],
            symbol: '',
            portfolioView: 'detail',
            portfolioListData: [],
            holdingList: [],
            aggregateData: [],
            width: 0,
            showWatchlist: false,
            showLoading: false,
            watchlistConfig: null
        };
    },
    async created() {
        this.namespace = utils.namespace('model-portfolio');
        this.throttleResize = utils.throttle(() => {
            const { width, height } = utils.innerSizes(this.$el);
            const headerHeight = this.$refs.header.clientHeight;
            this.$refs.content.style.height = `${height - headerHeight}px`;
            this.width = width;
        }, 300);
        this.portfolioListData = await this.getPortfolioList();
        this.portfolioView = this.settings.portfolioView;
        this.$emit('layout-change', this.portfolioView);
        this.portfolioId =
            this.settings.portfolioId || this.portfolioListData[0].portfolioId;
        this.symbol = this.settings.symbol;
        this.getPortfolioDataById(this.portfolioId);
    },
    watch: {
        skin(value) {
            this.settings.skin = value;
        }
    },
    mounted() {
        utils.resizeObserver.observe(this.$el, this.throttleResize);
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this.throttleResize);
    },
    computed: {
        cls() {
            const cls = [this.namespace()];
            if (this.skin) {
                cls.push(this.namespace(`-${utils.getSkinSuffix(this.skin)}`));
            }
            return cls;
        },
        mwcIds() {
            return `mwc-markets-watchlist-${utils.guid()}`;
        },
        menusCollapse() {
            if (this.width < 460) {
                return 'collapse';
            } else {
                return 'extend';
            }
        },
        layoutView() {
            return [
                {
                    id: 'detail',
                    name: 'Portfolio Details',
                    selected: 'detail' === this.portfolioView
                },
                {
                    id: 'overview',
                    name: 'Portfolio Overview',
                    selected: 'overview' === this.portfolioView
                }
            ];
        },
        portfolioList() {
            return this.portfolioListData.map(item => {
                return {
                    id: item.portfolioId,
                    name: item.name,
                    text: item.name,
                    selected: item.portfolioId === this.portfolioId
                };
            });
        },
        mergedRows() {
            return JSON.stringify(this.customRows);
        },
        userInfo() {
            const account = window.sessionStorage.getItem(
                'markets-components-app-account'
            );
            const { sessionId, instid, overridePaths } = JSON.parse(account);
            const url = overridePaths['@web'];
            return {
                sessionId,
                instid,
                url
            };
        },
        aggreateData() {
            const aggreate = {
                id: this.portfolioId.split(';')[0],
                enableGroup: false,
                name: 'Aggregate',
                portfolioWeight: '100.00'
            };
            DataPoints.forEach(item => {
                let value = this.overviewData[item.id];
                if (item.labelKey && value) {
                    if (item.dataType === 'date') {
                        value = value.replace(
                            /(\d+)(-|\.)(\d+)(-|\.)(\d+)/,
                            '$5$1$3'
                        );
                    }
                    aggreate[item.labelKey] = value;
                }
            });
            return [aggreate];
        }
    },
    methods: {
        exposeMethods() {
            return ['toggleSettings', 'toggleExport'];
        },
        toggleSettings(e) {
            this.$refs.marketWatchList.toggleSettings(e);
        },
        toggleExport(e, title) {
            this.$refs.marketWatchList.toggleExport(e, title);
        },

        doubleClick(params) {
            this.$emit('row-double-click', params.detail[0]);
        },
        hoverMenuClick(e) {
            const [item, params] = e.detail;
            this.$emit('hover-menu-click', item, params);
        },
        getPortfolioList() {
            const { sessionId, instid, url } = this.userInfo;
            this.showLoading = true;
            return axiosInstance({
                method: 'get',
                url: `${url}service/portfolioIds`,
                params: { instid, sessionId }
            }).then(res => {
                return res.data || [];
            });
        },
        async getPortfolioDataById(id) {
            const { sessionId, instid, url } = this.userInfo;
            return await axiosInstance({
                method: 'get',
                url: `${url}service/portfolioData`,
                params: { instid, sessionId, portfolioId: id }
            }).then(res => {
                const { portfolioData, holdingList } = res.data || {};
                this.overviewData = portfolioData;
                this.holdingList = holdingList.holdings;
                this.showLoading = false;
                this.symbol = holdingList.holdings
                    .map(item => {
                        return item.secId;
                    })
                    .join(',');
                this.generateCustomRowsData();
            });
        },
        generateAggreateData() {
            const aggreate = {
                id: this.portfolioId.split(';')[0],
                enableGroup: false,
                name: 'Aggregate',
                portfolioWeight: '100.00'
            };
            DataPoints.forEach(item => {
                let value = this.overviewData[item.id];
                if (item.labelKey && value) {
                    if (item.dataType === 'date') {
                        value = value.replace(
                            /(\d+)(-|\.)(\d+)(-|\.)(\d+)/,
                            '$5$1$3'
                        );
                    }
                    aggreate[item.labelKey] = value;
                }
            });
            return [aggreate];
        },
        generateCustomRowsData() {
            const customRows = this.holdingList.map(item => {
                return {
                    id: item.secId,
                    portfolioWeight: item.weight,
                    secId: item.secId,
                    name: item.name,
                    symbol: item.ticker,
                    IPODate: item.inceptionDate
                };
            });
            this.customRows = [...customRows, ...this.aggreateData];
            if (!this.showWatchlist) {
                this.watchlistConfig = JSON.stringify(
                    utils.extend(
                        true,
                        { settings: this.settings },
                        {
                            settings: {
                                symbol: this.symbol
                            }
                        }
                    )
                );
                this.showWatchlist = true;
                this.$nextTick(() => {
                    this.mwcMarketsWatchlist = this.$el.querySelector(
                        'mwc-markets-watchlist'
                    );
                    this.updateWatchlistData();
                });
                return;
            }
            this.updateWatchlistData();
        },
        changePortfolio(item) {
            this.portfolioId = this.settings.portfolioId = item.id;
            this.getPortfolioDataById(item.id);
            this.changePortfolioSettings();
        },
        changeLayout(item) {
            this.portfolioView = item.id;
            this.$emit('layout-change', item.id);
            this.changePortfolioSettings();
        },
        changeSettings(settings) {
            const saveSettings = settings.detail[0];
            this.changePortfolioSettings(saveSettings);
        },
        changePortfolioSettings(settings = {}) {
            const saveSettings = utils.extend(settings, {
                portfolioView: this.portfolioView,
                portfolioId: this.portfolioId,
                symbol: this.symbol
            });
            this.$emit('settings-changed', saveSettings);
        },
        updateWatchlistData() {
            this.mwcMarketsWatchlist.customRows = this.mergedRows;
            this.mwcMarketsWatchlist.updateGroupFunc = groupRows => {
                groupRows.forEach(item => {
                    if (item.subs) {
                        item.portfolioWeight = item.subs.reduce((d, c) => {
                            return d + c.portfolioWeight;
                        }, 0);
                        item.subs.forEach(d => {
                            if (!d.subs) {
                                d.keyWeight03 =
                                    (d.portfolioWeight / item.portfolioWeight) *
                                    100;
                            } else {
                                d.subs.forEach(f => {
                                    if (
                                        !Object.prototype.hasOwnProperty.call(
                                            f,
                                            '__group__'
                                        )
                                    ) {
                                        f.keyWeight02 =
                                            (f.portfolioWeight /
                                                item.portfolioWeight) *
                                            100;
                                    }
                                });
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        d,
                                        '__group__'
                                    ) &&
                                    !Object.prototype.hasOwnProperty.call(
                                        d.subs[0],
                                        '__group__'
                                    )
                                ) {
                                    d.keyWeight02 = d.subs.reduce((d, c) => {
                                        return d + c.keyWeight02;
                                    }, 0);
                                }
                            }
                        });
                    }
                });
            };
        }
    }
};
</script>
<style lang="scss">
$namespace: 'mwc-markets-model-portfolio';
.#{$namespace} {
    &-body {
        height: 100%;
        &-header {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .markets-ui-select {
                margin-right: 20px;
                width: 200px;
            }
            .markets-ui-button-group {
                height: 26px;
            }
        }
        &-content {
            width: 100%;
        }
    }
    &.markets-ui-container__dark-gray {
        .#{$namespace} {
            &-body {
                &-header {
                    background-color: #1e1e1e;
                }
            }
        }
    }
}
</style>
