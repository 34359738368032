import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export const DataPoints = [
    {
        id: 'HS00X',
        label: 'Cash',
        labelKey: 'cashComposition',
        groupId: 'composition',
        groupName: 'Composition'
    },
    {
        id: 'HS02E',
        label: 'Stock',
        groupId: 'composition',
        groupName: 'Composition'
    },
    {
        id: 'HS02D',
        label: 'Bond',
        labelKey: 'fixedIncomeComposition',
        groupId: 'composition',
        groupName: 'Composition'
    },
    {
        id: 'HS05J',
        label: 'Other',
        labelKey: 'otherComposition',
        groupId: 'composition',
        groupName: 'Composition',
        hasBorder: true
    },
    {
        id: 'all',
        label: 'Total',
        groupId: 'composition',
        groupName: 'Composition',
        sub: ['HS00X', 'HS02E', 'HS02D', 'HS05J']
    },
    {
        id: 'HS009',
        label: 'Americas',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown',
        hasBorder: true
    },
    {
        id: 'HS05C',
        label: 'North America',
        labelKey: 'northAmericaPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS06D',
        label: 'Latin America',
        labelKey: 'latinAmericaPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS03D',
        label: 'Greater Europe',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown',
        hasBorder: true
    },
    {
        id: 'HS06E',
        label: 'United Kingdom',
        labelKey: 'unitedKingdomPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS07S',
        label: 'Europe Developed',
        labelKey: 'europeDevelopedPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS06H',
        label: 'Europe Emerging',
        labelKey: 'europeEmergingPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS006',
        label: 'Africa/Middle East',
        labelKey: 'africaMiddleEastPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS03C',
        label: 'Greater Asia',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown',
        hasBorder: true
    },
    {
        id: 'HS06K',
        label: 'Japan',
        labelKey: 'japanPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS06L',
        label: 'Australasia',
        labelKey: 'australasiaPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS06N',
        label: 'Asia Developed',
        labelKey: 'asiaDevelopedPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'HS06M',
        label: 'Asia Emerging',
        labelKey: 'asiaEmergingPercentage',
        groupId: 'worldRegionBreakdown',
        groupName: 'World Region Breakdown'
    },
    {
        id: 'cyclical',
        label: 'Cyclical',
        iconName: 'alert',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown',
        hasBorder: true,
        sub: ['AA03K', 'AA03M', 'AA03T', 'AA03Q']
    },
    {
        id: 'AA03K',
        label: 'Basic Matls',
        labelKey: 'basicMaterials',
        iconName: 'ip-sector-basic-materials',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03M',
        label: 'Cons Cyclical',
        labelKey: 'consumerCyclical',
        iconName: 'ip-sector-consumer-cyclical',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03T',
        label: 'Financial Svcs',
        labelKey: 'financialServices',
        iconName: 'ip-sector-financial-services',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03Q',
        label: 'Real Estate',
        labelKey: 'realEstate',
        iconName: 'ip-sector-real-estate',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'sensitive',
        label: 'Sensitive',
        iconName: 'alert',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown',
        hasBorder: true,
        sub: ['AA03L', 'AA03S', 'AA03P', 'AA03R']
    },
    {
        id: 'AA03L',
        label: 'Comm Svcs',
        labelKey: 'communicationServices',
        iconName: 'ip-sector-consumer-defensive',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03S',
        label: 'Energy',
        labelKey: 'energy',
        iconName: 'ip-sector-energy',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03P',
        label: 'Industrials',
        labelKey: 'industrials',
        iconName: 'ip-sector-industrials',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03R',
        label: 'Technology',
        labelKey: 'technology',
        iconName: 'ip-sector-technology',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'defensive',
        label: 'Defensive',
        iconName: 'alert',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown',
        hasBorder: true,
        sub: ['AA03N', 'AA03O', 'AA03U']
    },
    {
        id: 'AA03N',
        label: 'Cons Defensive',
        labelKey: 'consumerDefensive',
        iconName: 'ip-sector-consumer-defensive',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03O',
        label: 'Healthcare',
        labelKey: 'healthcare',
        iconName: 'ip-sector-healthcare',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA03U',
        label: 'Utilities',
        labelKey: 'utilities',
        iconName: 'ip-sector-utilities',
        groupId: 'equitySectorBreakdown',
        groupName: 'Equity Sector Breakdown'
    },
    {
        id: 'AA0G0',
        label: 'Government',
        labelKey: 'government',
        groupId: 'fixedIncomeSectorBreakdown',
        groupName: 'Fixed Income Sector Breakdown'
    },
    {
        id: 'AA0G1',
        label: 'Municipal',
        labelKey: 'municipal',
        groupId: 'fixedIncomeSectorBreakdown',
        groupName: 'Fixed Income Sector Breakdown'
    },
    {
        id: 'AA0G2',
        label: 'Corporate',
        labelKey: 'corporate',
        groupId: 'fixedIncomeSectorBreakdown',
        groupName: 'Fixed Income Sector Breakdown'
    },
    {
        id: 'AA0G3',
        label: 'Securitized',
        labelKey: 'securitized',
        groupId: 'fixedIncomeSectorBreakdown',
        groupName: 'Fixed Income Sector Breakdown'
    },
    {
        id: 'AA0G4',
        label: 'Cash and Cash Equivalents',
        labelKey: 'cashAndEquivalents',
        groupId: 'fixedIncomeSectorBreakdown',
        groupName: 'Fixed Income Sector Breakdown'
    },
    {
        id: 'AA0G5',
        label: 'Derivative',
        labelKey: 'derivative',
        groupId: 'fixedIncomeSectorBreakdown',
        groupName: 'Fixed Income Sector Breakdown'
    },
    {
        id: 'HS004',
        label: 'AAA',
        labelKey: 'creditQualityAaa',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS003',
        label: 'AA',
        labelKey: 'creditQualityAa',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS002',
        label: 'A',
        labelKey: 'creditQualityA',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS00G',
        label: 'BBB',
        labelKey: 'creditQualityBbb',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS00F',
        label: 'BB',
        labelKey: 'creditQualityBb',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS00E',
        label: 'B',
        labelKey: 'creditQualityB',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS00H',
        label: 'Below B',
        labelKey: 'creditQualityBelowB',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS05E',
        label: 'Not Rated',
        labelKey: 'creditQualityNotRated',
        groupId: 'creditRatingBreakdown',
        groupName: 'Credit Rating Breakdown'
    },
    {
        id: 'HS05X',
        label: 'Price to Earnings',
        groupId: 'equityValuationPriceMultiples',
        groupName: 'Equity Valuation Price Multiples'
    },
    {
        id: 'HS05V',
        label: 'Price to Book Value',
        groupId: 'equityValuationPriceMultiples',
        groupName: 'Equity Valuation Price Multiples'
    },
    {
        id: 'HS05U',
        label: 'Price to Sales',
        groupId: 'equityValuationPriceMultiples',
        groupName: 'Equity Valuation Price Multiples'
    },
    {
        id: 'HS05W',
        label: 'Price to Cash Flow',
        groupId: 'equityValuationPriceMultiples',
        groupName: 'Equity Valuation Price Multiples'
    },
    {
        id: 'HS08F',
        label: 'ROE %',
        groupId: 'financialRatios',
        groupName: 'Financial Ratios'
    },
    {
        id: 'HS08E',
        label: 'ROA %',
        groupId: 'financialRatios',
        groupName: 'Financial Ratios'
    },
    {
        id: 'HS08D',
        label: 'Net Margin %',
        groupId: 'financialRatios',
        groupName: 'Financial Ratios'
    },
    {
        id: 'HS06U',
        label: 'Debt to Capital %',
        groupId: 'financialRatios',
        groupName: 'Financial Ratios'
    },
    {
        id: 'HS02F',
        label: 'Avg Eff Duration',
        labelKey: 'effectiveDuration',
        groupId: 'fixedIncomeStyle',
        groupName: 'Fixed Income Style'
    },
    {
        id: 'HS05B',
        label: 'Avg Eff Maturity',
        labelKey: 'effectiveMaturity',
        groupId: 'fixedIncomeStyle',
        groupName: 'Fixed Income Style'
    },
    {
        id: 'HS00C',
        label: 'Avg Credit Quality',
        labelKey: 'averageCreditQualityName',
        groupId: 'fixedIncomeStyle',
        groupName: 'Fixed Income Style',
        dataType: 'string'
    },
    {
        id: 'HS019',
        label: 'Avg Wtd Coupon',
        groupId: 'fixedIncomeStyle',
        groupName: 'Fixed Income Style'
    },
    {
        id: 'PM002',
        labelKey: 'secYield'
    },
    {
        id: 'PD003',
        labelKey: 'oneDayReturn'
    },
    {
        id: 'PD005',
        labelKey: 'oneWeekReturn'
    },
    {
        id: 'PD007',
        labelKey: 'oneMonthReturn'
    },
    {
        id: 'PD009',
        labelKey: 'threeMonthReturn'
    },
    {
        id: 'PM493',
        labelKey: 'sixMonthReturn'
    },
    {
        id: 'PD00D',
        labelKey: 'oneYearReturn'
    },
    {
        id: 'PD014',
        labelKey: 'twoYearReturn'
    },
    {
        id: 'PM494',
        labelKey: 'tenYearReturn'
    },
    {
        id: 'PM495',
        labelKey: 'fifteenYearReturn'
    },
    {
        id: 'PD00B',
        labelKey: 'ytdReturn'
    },
    {
        id: 'PD00H',
        labelKey: 'fiveYearReturn'
    },
    {
        id: 'PD00F',
        labelKey: 'threeYearReturn'
    },
    {
        id: 'ST152',
        labelKey: 'sector'
    },
    {
        id: 'RR152',
        labelKey: 'upsideCaptureRatioFor1Year'
    },
    {
        id: 'RR158',
        labelKey: 'downsideCaptureRatioFor1Year'
    },
    {
        id: 'RR011',
        labelKey: 'sharpeRatioFor3Year'
    },
    {
        id: 'OS01X',
        labelKey: 'tradeExchange'
    },
    {
        id: 'OS03S',
        labelKey: 'turnover'
    },
    {
        id: 'HS000',
        labelKey: 'portfolioDate',
        dataType: 'date'
    },
    {
        id: 'HS140',
        labelKey: 'usEquityComposition'
    },
    {
        id: 'HS141',
        labelKey: 'nonUSEquityComposition'
    },
    {
        id: 'HS02D',
        labelKey: 'fixedIncomeComposition'
    }
];

export const notPercentGroup = [
    'fixedIncomeStyle',
    'financialRatios',
    'equityValuationPriceMultiples'
];

export function getDataPointsGroup() {
    const map = {};
    DataPoints.forEach(item => {
        if (item.groupId) {
            if (!map[item.groupId]) {
                map[item.groupId] = {
                    label: item.groupName,
                    dataPoints: [],
                    id: item.groupId
                };
            }
            map[item.groupId].dataPoints.push(item.id);
        }
    });
    return map;
}

export function getDataPointsItemsById(id) {
    return utils.find(DataPoints, item => {
        return item.id === id;
    });
}
