<template>
    <div :class="cls">
        <div
            ref="waterfallItem"
            :class="namespace('waterfallItem')"
            v-for="(list, index) in overviewData"
            :key="index"
        >
            <div
                :class="[
                    namespace('wrapper'),
                    item.isTitle ? namespace('wrapper-title') : '',
                    item.hasBorder ? namespace('border-bottom') : ''
                ]"
                v-for="item in list"
                :key="item.id"
            >
                <div :class="namespace('wrapper-name')">
                    <markets-ui-icon
                        v-if="item.iconName"
                        :name="item.iconName"
                        :skin="skin"
                    ></markets-ui-icon>
                    {{ item.name }}
                </div>
                <div :class="[namespace('wrapper-value')]">
                    {{ item.value }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
import {
    getDataPointsGroup,
    getDataPointsItemsById,
    notPercentGroup
} from './datapoints';
export default {
    name: 'mwc-markets-model-overview',
    data() {
        return {
            overviewData: []
        };
    },
    props: {
        skin: {
            type: String,
            default: ''
        },
        dataModel: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    watch: {
        dataModel(value) {
            this.generateData(value);
        }
    },
    created() {
        this.namespace = utils.namespace('model-overview');
        this.generateData();
        this.throttleResize = utils.throttle(() => {
            this.calculateWidth();
        }, 300);
    },
    mounted() {
        utils.resizeObserver.observe(this.$el, this.throttleResize);
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this.throttleResize);
    },
    computed: {
        cls() {
            const cls = [this.namespace()];
            if (this.skin) {
                cls.push(this.namespace(`-${utils.getSkinSuffix(this.skin)}`));
            }
            return cls;
        }
    },
    methods: {
        calculateWidth() {
            const { width } = utils.innerSizes(this.$el);
            let columns = 2;
            if (width <= 640) {
                columns = 2;
            } else if (width <= 960) {
                columns = 4;
            } else {
                columns = 6;
            }
            let scrollWidth = 0;
            //judge has scrollbar, width need reduce 17px
            if (this.$el.offsetHeight < this.$el.scrollHeight) {
                scrollWidth = 17;
            }
            const itemWidth =
                (width - scrollWidth - 10 * (columns - 1)) / columns;
            this.getWaterfall(columns, itemWidth);
        },
        getWaterfall(columns, itemWidth) {
            const item = this.$refs.waterfallItem;
            if (!item) {
                return;
            }
            const saveHeight = [];
            for (let i = 0; i < item.length; i++) {
                item[i].style.width = `${itemWidth}px`;
                item[i].classList.remove(this.namespace('top-bolder'));
                if (i < columns) {
                    item[i].style.top = '10px';
                    item[i].style.left = `${(itemWidth + 8) * i}px`;
                    saveHeight.push(item[i].offsetHeight); //save the first rows height
                } else {
                    const min = Math.min(...saveHeight);
                    const minIdx = utils.findIndex(
                        saveHeight,
                        item => item === min
                    );
                    //set current item position
                    item[i].style.top = `${saveHeight[minIdx] + 20}px`;
                    item[i].style.left = `${item[minIdx].offsetLeft}px`;
                    item[i].classList.add(this.namespace('top-bolder'));
                    // reset saveHeight min height
                    saveHeight[minIdx] =
                        saveHeight[minIdx] + item[i].offsetHeight + 10;
                }
            }
        },
        generateData(resData = {}) {
            this.overviewData = Object.keys(getDataPointsGroup()).map(key => {
                const item = getDataPointsGroup()[key];
                const value =
                    notPercentGroup.indexOf(key) > -1 ? '' : 'Portfolio %';

                const data = [
                    {
                        id: item.id,
                        name: item.label,
                        value,
                        isTitle: true
                    }
                ];
                item.dataPoints.forEach(dp => {
                    const dpItems = getDataPointsItemsById(dp);
                    let dpValue;
                    if (dpItems.dataType !== 'string') {
                        let value = resData[dp] ? +resData[dp] : 0;
                        if (dpItems.sub) {
                            value = dpItems.sub.reduce((d, c) => {
                                const val = resData[c] ? +resData[c] : 0;
                                return d + val;
                            }, 0);
                        }
                        dpValue = value.toFixed(2);
                    } else {
                        dpValue = utils.getFormatValue({
                            value: resData[dp],
                            dataType: 'string'
                        });
                    }

                    data.push({
                        id: dpItems.id,
                        name: dpItems.label,
                        iconName: dpItems.iconName,
                        hasBorder: dpItems.hasBorder,
                        value: dpValue
                    });
                });
                return data;
            });
        }
    }
};
</script>
<style lang="scss">
$namespace: 'mwc-markets-model-overview';
$default-bg-color: #f2f2f2;
$default-header-color: #ffffff;
$default-border-color: #5e5e5e;
.#{$namespace} {
    height: 100%;
    position: relative;
    overflow: auto;
    &-waterfallItem {
        position: absolute;
        box-sizing: border-box;
    }
    &-top-bolder {
        border-top: 1px solid #000000;
    }
    &-border-bottom {
        border-bottom: 1px solid #cccccc;
    }
    &-wrapper {
        display: flex;
        font-size: 14px;
        line-height: 18px;
        justify-content: space-between;
        align-items: center;
        &-name {
            display: flex;
            .markets-ui-icon {
                margin-right: 5px;
            }
        }
        &-title {
            font-size: 10px;
            font-weight: bold;
            .#{$namespace}-wrapper-name {
                color: #1e1e1e;
            }
            .#{$namespace}-wrapper-value {
                color: #5e5e5e;
            }
        }
    }
    &.#{$namespace}--dark-gray {
        color: $default-header-color;
        .#{$namespace} {
            &-top-bolder {
                border-color: $default-bg-color;
            }
            &-border-bottom {
                border-color: $default-border-color;
            }
            &-wrapper-name {
                .mds-icon___markets {
                    fill: $default-header-color;
                    stroke: $default-header-color;
                }
            }
            &-wrapper-title {
                .#{$namespace}-wrapper-name {
                    color: $default-bg-color;
                }
                .#{$namespace}-wrapper-value {
                    color: $default-bg-color;
                }
            }
        }
    }
}
</style>
